.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  background-color: black; /* Set your desired background color */
  position: absolute; /* Changed to absolute for better positioning */
  width: 100%; /* Full width */
}

.text {
  font-size: 4rem; /* Default font size for large screens */
  background: linear-gradient(to top, #FE320A, #ff671bf7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0; /* Initially hidden for animation */
  position: absolute;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.1rem;
}

/* Responsive styles */
@media (max-width: 768px) {
  .text {
    font-size: 3rem; /* Adjust font size for tablets */
  }
}

@media (max-width: 480px) {
  .text {
    font-size: 1rem; /* Adjust font size for mobile devices */
  }
}
