@keyframes animation-page2 {
  0% {
    filter: blur(30px);
    transform: translate(10%, -10%) skew(0);
  }
  100% {
    filter: blur(30px);
    transform: translate(-10%, 10%) skew(-10deg);
  }
}

.border-animation_two {
  width: 30vw;
  height: 10vw;
  position: absolute;
  border-radius: 50%;
  left: 0vh;
  background: linear-gradient(to top, #FE320A, #ff671bf7);
  top: calc(35% - 10vw); /* Adjust to show half above */
  transform: translate(-50%, -50%); /* Center the element */
  filter: blur(25px);
  animation: animation-page2 6s infinite alternate ease-in-out;
  z-index: 0; /* Ensure it's below the contact content */
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .border-animation_two {
    width: 40vw;
    height: 40vw;
  }
}

@media (max-width: 768px) {
  .border-animation_two {
    width: 50vw;
    height: 50vw;
  }
}

@media (max-width: 480px) {
  .border-animation_two {
    margin-left: -20vh;
    margin-top: -14vh;
    width: 70vw;
    height: 60vw;
    top: calc(50% - 30vw); /* Adjust for smaller screens */
  }
}

.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20vh; /* Adjust as necessary */
  position: relative;
  z-index: 1; /* Ensure it is above the animated element */
}

.tilted-section {
  width: 100%;
  height: 40vh;
  background-color: #1e3a8a; /* Use a Tailwind color or hex */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Ensure it layers above the animation */
}

.tilted-content {
  text-align: center;
  color: white;
}

.email-button {
  margin-top: 1rem;
  padding: 0.5rem 1.5rem;
  background-color: #f97316; /* Use a Tailwind color or hex */
  color: white;
  border-radius: 0.375rem; /* Tailwind's rounded-md */
  transition: background-color 0.3s ease;
}

.email-button:hover {
  background-color: #ea580c; /* Use a Tailwind color or hex */
}
