@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


/* Add smooth scroll behavior */
html {
  scroll-behavior: smooth;
}

/* Custom scrollbar styling */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #F92E2A; /* Scrollbar color */
  border-radius: 6px; /* Rounded corners for modern look */
  border: 3px solid #f1f1f1; /* Padding around the scrollbar */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #d92622; /* Darker color on hover */
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #F92E2A #f1f1f1;
}



/* Light Mode (default) */
.light-mode {
    background-color: #ffffff;
    color: #000000;
  }
  
  /* Dark Mode */
  .dark-mode {
    background-color: #181818;
    color: #ffffff;
  }
  
  /* Toggle Button Styling */
  .toggle-btn {
    position: fixed;
    top: 30px;
    right: 20px;
    padding: 10px 20px;
    background-color: #F94D12;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  