/* Navbar Styling */
.navbar {
  transition: all 0.3s ease-in-out;
}

.no-scroll {
  overflow: hidden;
}

.text-white {
  color: #ffffff;
}

.text-black {
  color: #000000;
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white; /* Adjust as needed */
  box-shadow: 0 4px 2px -2px gray;
  z-index: 1000;
}

/* Dark Mode for Navbar */
.dark-navbar {
  background-color: #181818; /* Dark background */
  color: #ffffff; /* Light text */
}

.dark-navbar .nav-item {
  color: #ffffff; /* Light text color in dark mode */
}

.dark-navbar .nav-item:hover {
  color: #F92E2A; /* Hover color */
}

/* Navbar hover underline animation */
.nav-item {
  position: relative;
  display: inline-block;
  color: #000; /* Customize your text color */
}

.nav-item::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  background-color: #F92E2A; /* The color of the underline */
  left: 0;
  bottom: 1px; /* Adjust distance below the text */
  transition: width 0.3s ease-in-out;
}

.nav-item:hover::after {
  width: 100%;
}

/* Logo and Slider Styling */
.logo img {
  cursor: pointer;
}

.slider {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 80vh;
}

.slide {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.slide.active {
  opacity: 1;
}


/* Add this to your Navbar.css file */

/* Active link styles */
.active {
  color: #F92E2A; /* Active link color */
  text-decoration: underline; /* Underline for active link */
}

/* Ensure nav items don't have underline by default */
.nav-item {
  text-decoration: none; /* Prevent default underline */
}

/* Rest of your CSS remains unchanged */
