/* Default styles */
.underline-animation {
  position: relative;
  display: inline-block;
  color: #000; /* Default text color for light mode */
}

.underline-animation::before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  background-color: #F92E2A;
  bottom: -4px; /* Adjust this value to control the distance below the text */
  left: 50%;
  transform: translateX(-50%);
  transition: width 0.5s ease-in-out;
}

.underline-animation:hover::before {
  width: 100%;
  left: 0;
  transform: translateX(0);
}

/* Dark mode styles */
.dark-mode .underline-animation {
  color: #ffffff; /* Text color for dark mode */
}

.dark-mode .underline-animation::before {
  background-color: #F92E2A; /* Adjust as needed for dark mode */
}

.border-animation {
  width: 30vw;
  height: 26vw;
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(to top, #FE320A, #ff671bf7);
  top: -2%;
  left: -10%;
  filter: blur(25px);
  animation: animation-page2 6s infinite alternate ease-in-out;
}

@keyframes animation-page2 {
  0% {
      filter: blur(30px);
      transform: translate(10%, -10%) skew(0);
  }
  100% {
      filter: blur(30px);
      transform: translate(-10%, 10%) skew(-10deg);
  }
}
